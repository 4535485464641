import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "common/config/firebase";
import { callApi } from "adapter/ApiService";

import {
  UserState,
  signinData,
  PhoneSigninData,
  IObjectKeys,
} from "@/interface/PropTypeCollection";
import Helper from "common/libraries/Helper";

const initialState: UserState = {
  role: 1,
  avatar: null,
  authInfo: null,
  status: "idle",
  isAuth: null,
  message: "",
};

export const signin = createAsyncThunk(
  "UserResult/login",
  async (
    signinCredentials: signinData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { name, password, role } = signinCredentials;
    try {
      const response = await callApi("user", "get/login", {
        name,
        password,
        role,
      });

      if (response && response.state == 1) {
        var keyTag: string = "";
        var id = response.data.id;
        switch (role) {
          case 1:
            keyTag = `${process.env.REACT_APP_MEMBER_LOGIN_KEY}`;
            break;

          case 2:
            keyTag = `${process.env.REACT_APP_MERCHANT_LOGIN_KEY}`;
            break;
        }
        window.localStorage.setItem(
          `${keyTag}-${id}`,
          JSON.stringify(response.data)
        );
        return fulfillWithValue(response.data);
      } else {
        console.log("rejecting");
        return rejectWithValue({ error: { message: "incorrect role value" } });
      }
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const UserSlice = createSlice({
  name: "UserResult",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    updateUser: (state, action) => {
      state.authInfo = action.payload;
      if (state.authInfo) {
        state.isAuth = true;
      }
    },
    updateStatus: (state, action) => {
      const { status, message } = action.payload;
      state.status = status;
      state.message = message;
    },
    cleanMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = "done";
        state.isAuth = true;
        state.authInfo = action.payload;
      })
      .addCase(signin.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { data, message },
        } = resp;
        state.status = "failed";
        state.isAuth = false;
        let authErroMessage = "you have no access permisson here";
        state.message = authErroMessage;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setRole, cleanMessage, updateUser, updateStatus } =
  UserSlice.actions;

export default UserSlice.reducer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import AccountMenu from "components/navigation/AccountMenu";
import LeftColumnWelcome from "components/dashboard/merchant/LeftColumn";

export default function AdminNav() {
  return (
    <>
      {/*
            <HeaderMenu />
    */}
      {/*
            <div className="subnavbar desktop">
                <div className="container">
                    <ul className="navbar-nav member-dashboard-navbarmenu">
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/account">My Account</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" to="/admin/dashboard">Dashboard</Link>
                        </li>
                    </ul>
                </div>
            </div>
    */}
    </>
  );
}

import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import async from "./components/Async";
import Template from "pages/Template";
import NotFound from "pages/NotFound";
import Maintenance from "./pages/Maintenance";

/* Dashboard - Member */
const MemberDashboard = async(() => import("pages/member/PageDashboard"));
const MemberDefault = async(() => import("components/dashboard/member"));
const MemberReferFriend = async(
  () => import("components/dashboard/member/ReferFriend")
);
const MemberPurchaseRebate = async(
  () => import("components/dashboard/member/PurchaseRecord")
);
const MemberTeamPerformance = async(
  () => import("components/dashboard/member/TeamPerformance")
);
const MemberIncomeSummary = async(
  () => import("components/dashboard/member/IncomeSummary")
);
const MemberWithdraw = async(
  () => import("components/dashboard/member/WithdrawRecord")
);
const MemberStoreComission = async(
  () => import("components/dashboard/member/StoreReward")
);
const MemberProfile = async(
  () => import("components/dashboard/member/EditProfile")
);
const MemberSecurity = async(
  () => import("components/dashboard/member/Account")
);

/* Dashboard - Merchant */
const MerchantDashboard = async(() => import("pages/merchant/PageDashboard"));
const MerchantDefault = async(() => import("components/dashboard/merchant"));
const MerchanSetupCashier = async(
  () => import("components/dashboard/merchant/CashierManage")
);
const MerchantCashierRecords = async(
  () => import("components/dashboard/common/CashierTransRecord")
);
const MerchantSalesSummary = async(
  () => import("components/dashboard/merchant/SalesMonthlySummary")
);
const MerchantPaymentRecords = async(
  () => import("components/dashboard/merchant/PaymentRecord")
);

const MerchantRate = async(() => import("components/dashboard/merchant/Rate"));

/* Dashboard  - Cashier  */
const CashierDashboard = async(() => import("pages/cashier/PageDashboard"));
const CashierDefault = async(() => import("components/dashboard/cashier"));
const CashierRecordTransac = async(
  () => import("components/dashboard/cashier/RecordPurchase")
);
const CashierTransacList = async(
  () => import("components/dashboard/cashier/TransList")
);
const CashierDisputeTransac = async(
  () => import("components/dashboard/cashier/FileDispute")
);

/* Dashboard  - Admin  */

const AdminDashboard = async(() => import("pages/admin/PageDashboard"));
const AdminDefault = async(() => import("components/dashboard/admin"));
const AdminConfigRate = async(
  () => import("components/dashboard/admin/config/RateTab")
);

const AdminConfigCommissionChart = async(
  () => import("components/dashboard/admin/config/CommissionChart")
);
const AdminManageMerchant = async(
  () => import("components/dashboard/admin/manage/MerchantListTab")
);
const AdminManageMember = async(
  () => import("components/dashboard/admin/manage/MemberListTab")
);
const AdminManageCashier = async(
  () => import("components/dashboard/admin/manage/CashierListTab")
);

const AdminReportMember = async(
  () => import("components/dashboard/admin/report/MemberNumber")
);
const AdminReportMerchant = async(
  () => import("components/dashboard/admin/report/MerchantNumber")
);
const AdminReportSales = async(
  () => import("components/dashboard/admin/report/MerchantSales")
);
const AdminTeamPerformance = async(
  () => import("components/dashboard/admin/report/TeamPerformance")
);
const PlatformRewards = async(
  () => import("components/dashboard/admin/report/PlatformRewards")
);
const AdminPurchaseRebate = async(
  () => import("components/dashboard/admin/report/PurchaseRebate")
);
const AdminStoreReward = async(
  () => import("components/dashboard/admin/report/StoreReward")
);
const AdminTransaction = async(
  () => import("components/dashboard/admin/report/TransactionRecord")
);
const AdminWithdraw = async(
  () => import("components/dashboard/admin/report/WithdrawRecord")
);
const AdminReceivablePayment = async(
  () => import("components/dashboard/admin/report/ReceivablePayment")
);
const AdminReceivableOverdue = async(
  () => import("components/dashboard/admin/report/ReceivableOverdue")
);

const AdminInviteUser = async(
  () => import("components/dashboard/admin/InviteUser")
);

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template />}>
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/account" element={<NotFound />}>
            <Route path="orders" element={<NotFound />} />
          </Route>
          <Route path="/member">
            <Route index={true} element={<MemberDashboard />} />
            <Route path="page" element={<MemberDashboard />}>
              <Route index={true} element={<MemberDashboard />} />
              <Route path="dashboard" element={<MemberDefault />} />
              <Route path="refer-friend" element={<MemberReferFriend />} />
              <Route
                path="reward-records/purchase-rebate"
                element={<MemberPurchaseRebate />}
              />
              <Route
                path="reward-records/team-performance"
                element={<MemberTeamPerformance />}
              />
              <Route
                path="reward-records/income-summary"
                element={<MemberIncomeSummary />}
              />
              <Route
                path="reward-records/store-comission"
                element={<MemberStoreComission />}
              />
              <Route path="withdraw" element={<MemberWithdraw />} />
              <Route path="profile/setting" element={<MemberProfile />} />
              <Route path="profile/security" element={<MemberSecurity />} />
            </Route>
          </Route>

          <Route path="/merchant">
            <Route index={true} element={<MerchantDashboard />} />
            <Route path="page" element={<MerchantDashboard />}>
              <Route index={true} element={<MerchantDashboard />} />
              <Route path="dashboard" element={<MerchantDefault />} />
              <Route
                path="manage-setup-cashiers"
                element={<MerchanSetupCashier />}
              />
              <Route
                path="sales/cashier-records"
                element={<MerchantCashierRecords isCashier={false} />}
              />
              <Route
                path="sales/monthly-summary"
                element={<MerchantSalesSummary />}
              />
              <Route
                path="payment-records"
                element={<MerchantPaymentRecords />}
              />
              <Route path="profile/rate" element={<MerchantRate />} />
            </Route>
          </Route>

          <Route path="/cashier">
            <Route path="page" element={<CashierDashboard />}>
              <Route index={true} element={<CashierDashboard />} />
              <Route path="dashboard" element={<CashierDefault />} />
              <Route
                path="transaction-list"
                element={<MerchantCashierRecords isCashier={true} />}
              />
              {/*
              <Route path="transaction-list" element={<CashierTransacList />} />
              */}
              <Route
                path="record-transaction"
                element={<CashierRecordTransac />}
              />
              <Route path="dispute-transaction">
                <Route index={true} element={<CashierDisputeTransac />} />
                <Route path=":transId" element={<CashierDisputeTransac />} />
              </Route>
            </Route>
          </Route>
          <Route path="/admin">
            <Route path="page" element={<AdminDashboard />}>
              <Route index={true} element={<AdminDashboard />} />
              <Route path="dashboard" element={<AdminDefault />} />
              <Route path="manage/merchant" element={<AdminManageMerchant />} />
              <Route path="manage/member" element={<AdminManageMember />} />
              <Route path="manage/cashier" element={<AdminManageCashier />} />
              <Route path="config/rate" element={<AdminConfigRate />} />
              <Route
                path="config/commission-chart"
                element={<AdminConfigCommissionChart />}
              />
              <Route path="report/member" element={<AdminReportMember />} />
              <Route path="report/merchant" element={<AdminReportMerchant />} />
              <Route path="report/sales" element={<AdminReportSales />} />
              <Route
                path="report/platform-rewards"
                element={<PlatformRewards />}
              />
              <Route
                path="report/team-performance"
                element={<AdminTeamPerformance />}
              />
              <Route
                path="report/purchase-rebate"
                element={<AdminPurchaseRebate />}
              />
              <Route
                path="report/store-reward"
                element={<AdminStoreReward />}
              />
              <Route path="report/transaction" element={<AdminTransaction />} />
              <Route path="report/withdraw" element={<AdminWithdraw />} />
              <Route
                path="report/receivable-payment"
                element={<AdminReceivablePayment />}
              />
              <Route
                path="report/receivable-overdue"
                element={<AdminReceivableOverdue />}
              />
              <Route
                path="invite/merchant"
                element={
                  <AdminInviteUser userType="merchant" inviteRole="admin" />
                }
              />
              <Route
                path="invite/member"
                element={
                  <AdminInviteUser userType="member" inviteRole="admin" />
                }
              />
            </Route>
          </Route>
          {/* Bottom Nav */}

          {/* Botton Nav end */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

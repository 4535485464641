import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import ButtonLink from "../../widgets/ButtonLinks";
import { useUserStatus, useAppDispatch } from "common/hooks";
import {
  setCurPage,
  setNavContent,
  setMobileNavContent,
} from "common/features/PageSetupSlice";
import { useAppSelector } from "common/hooks";
import AccountMenu from "./AccountMenu";
import HeaderMenu from "./HeaderMenu";
import { MenuProp } from "common/libraries/interface/PropTypeCollection";

export default function NavFullModel() {
  const dispatch = useAppDispatch();
  const { userRole, user, loading, error } = useUserStatus();

  const navDesktopContent = [
    {
      page: "how-it-works",
      title: "How it Works",
      link: "/how-it-works",
    },
    {
      page: "member",
      title: "Member",
      link: "/page/member",
    },
    {
      page: "merchant",
      title: "Merchant",
      link: "/page/merchant",
    },
    {
      page: "shop",
      title: "Shopping",
      link: "/shopping",
    },
  ];

  useMemo(() => {
    if (loading === false) {
      let navContent: MenuProp[] = [
        {
          page: "how-it-works",
          title: "How it Works",
          link: "/how-it-works",
        },
        {
          page: "member",
          title: "Member",
          link: "/page/member",
        },
        {
          page: "merchant",
          title: "Merchant",
          link: "/page/merchant",
        },
        {
          page: "shop",
          title: "Shopping",
          link: "/shopping",
        },
      ];

      if (userRole == 1) {
        navContent.push({
          page: "member",
          title: "Member Dashboard",
          link: "/member/page/dashboard",
        });
      } else if (userRole == 2) {
        navContent.push({
          page: "merchant",
          title: "Dashboard",
          link: "/merchant/page/dashboard",
        });
      } else if (userRole == 3) {
        navContent.push({
          page: "merchant",
          title: "Merchant Dashboard",
          link: "/merchant/page/dashboard",
        });

        navContent.push({
          page: "member",
          title: "Member Dashboard",
          link: "/member/page/dashboard",
        });
      } else {
        navContent.push({
          page: "logout",
          title: "Logout",
          link: "/user/logout",
        });
      }
      // dispatch(setMobileNavContent(navContent));
      // dispatch(setNavContent(navDesktopContent));
    }
  }, [userRole]);

  return <HeaderMenu />;
}

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector, useUserStatus } from "common/hooks";
import {
  setCurPage,
  setNavContent,
  setMobileNavContent,
} from "common/features/PageSetupSlice";
import NavFullMode from "components/navigation/NavFullMode";
import NavMerchantDashboardMode from "components/dashboard/merchant/NavMerchantDashboardMode";
import NavMemberDashboardMode from "components/dashboard/member/NavMemberDashboardMode";
import NavAdminDashboardMode from "components/dashboard/admin/NavAdminDashboardMode";
import NavCashierDashboardMode from "components/dashboard/cashier/NavCashierDashboardMode";
import { MenuProp, FullMenuProp } from "@/interface/PropTypeCollection";

export default function SiteHeader() {
  const dispatch = useAppDispatch();
  const { userRole, user, loading, error } = useUserStatus();
  const { curPage } = useAppSelector((state) => state.PageSetup);
  useEffect(() => {
    let navDesktopContent: FullMenuProp[];

    if (loading === false) {
      switch (curPage) {
        case "signup":
        case "signin":
        case "member-refer-a-friend":
        case "merchant_dashboard":
        case "member_dashboard":
        case "admin_dashboard":
        case "cashier_dashboard":
          navDesktopContent = [];
          break;

        default:
          navDesktopContent = [];
          break;
      }

      let navContent: FullMenuProp[] = [];

      switch (userRole) {
        case 1:
          navContent.push({
            page: "member",
            title: "Member Dashboard",
            link: "/member/page/dashboard",
          });
          break;
        case 2:
          navContent.push({
            page: "merchant",
            title: "Merchant Dashboard",
            link: "/merchant/page/dashboard",
          });
          break;

        case 3:
          navContent.push({
            page: "merchant",
            title: "Merchant Dashboard",
            link: "/merchant/page/dashboard",
          });

          navContent.push({
            page: "member",
            title: "Member Dashboard",
            link: "/member/page/dashboard",
          });
          break;

        case 4:
          navContent.push({
            page: "Cashier",
            title: "Cashier Dashboard",
            link: "/cashier/page/dashboard",
          });
          break;

        case 8:
          navContent.push({
            page: "Admin",
            title: "Admin Dashboard",
            link: "/admin/page/dashboard",
          });
          break;

        default:
          navContent.push({
            page: "login",
            title: "Member Login",
            link: "/login/member",
          });
          navContent.push({
            page: "login",
            title: "Merchant Login",
            link: "/login/merchant",
          });
          break;
      }
      dispatch(setMobileNavContent(navContent));
      dispatch(setNavContent(navDesktopContent));
    }
  }, [userRole, curPage, loading]);

  const components = {
    full: NavFullMode,
    merchant_dashboard: NavMerchantDashboardMode,
    member_dashboard: NavMemberDashboardMode,
    admin_dashboard: NavAdminDashboardMode,
    cashier_dashboard: NavCashierDashboardMode,
  };

  const Story = (props) => {
    const SpecificStory = components[props.storyType];
    return <SpecificStory story={props.story} />;
  };

  let navMode: string = "full";

  switch (curPage) {
    case "merchant_dashboard":
      navMode = "merchant_dashboard";
      break;

    case "member_dashboard":
      navMode = "member_dashboard";
      break;

    case "admin_dashboard":
      navMode = "admin_dashboard";
      break;

    case "cashier_dashboard":
      navMode = "cashier_dashboard";
      break;

    default:
      navMode = "full";
      break;
  }
  return <Story storyType={navMode} story={null} />;
}

/* eslint class-methods-use-this: "off" */

const Helper = {
  getDeviceType() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      //remove ipad from mobile
      return "mobile";
    } else {
      return "desktop";
    }
  },

  createGroups(arr, perGroup) {
    const numGroups = Math.ceil(arr.length / perGroup);
    return new Array(numGroups)
      .fill("")
      .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
  },

  ucfirst(str) {
    if (str) {
      const s = str.toLowerCase();
      if (s.length >= 2) {
        return `${s[0].toUpperCase()}${s.substring(1)}`;
      } else if (s.length == 1) {
        return `${s[0].toUpperCase()}`;
      }
    } else {
      return "";
    }
  },

  jsEncode(s, k) {
    var enc = "";
    var str = "";
    // make sure that input is string
    str = s.toString();
    for (var i = 0; i < s.length; i++) {
      // create block
      var a = s.charCodeAt(i);
      // bitwise XOR
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }
    return enc;
  },

  isObject(val) {
    return typeof val === "object" && val !== null;
  },
  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  gotoDomPoistion(id) {
    const divFirst = document.getElementById(id);
    if (divFirst) {
      divFirst.scrollIntoView({ behavior: "smooth" });
    }
  },
  showMember12digit(id) {
    const str = `${id}`;
    if (str && str.length === 12) {
      const p1 = str.substring(0, 4);
      const p2 = str.substring(4, 8);
      const p3 = str.substring(8, 12);
      return `${p1}-${p2}-${p3}`;
    } else {
      return str;
    }
  },
  appLog(target, value) {
    if (process.env.REACT_APP_CODE_LOG) {
      console.log(`${target} >>:`, value);
    }
  },

  number_format(number, decimals, decPoint, thousandsSep) {
    return new Intl.NumberFormat("en-CA", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
  },

  showTransationNumber(num, size) {
    const newNum = `000000000${num}`;
    return `#${newNum.substring(-size)}`;
  },
  getMerchantLogo(logoUri, catSlug) {
    return logoUri
      ? `${process.env.REACT_APP_S3_URL}${logoUri}`
      : `${process.env.REACT_APP_IMG_HOME_LINK}shopping/default-logo/${catSlug}-default-logo.png`;
  },
  getMerchantCover(coverUri, catSlug) {
    return coverUri
      ? `${process.env.REACT_APP_S3_URL}${coverUri}`
      : `${process.env.REACT_APP_IMG_HOME_LINK}shopping/default-cover/${catSlug}-default-cover.jpeg`;
  },
  checkIsDecimal(number) {
    return parseFloat(number) != parseInt(number);
  },

  getAmountValue(val) {
    let retVal = val;
    if (typeof val === "string") {
      retVal = val.replace("$", "");
    }
    return parseFloat(retVal);
  },

  showAmountValue(val) {
    const retVal = this.getAmountValue(val);
    return `$${this.number_format(retVal, 2)}`;
  },

  saveSourceUrl(source) {
    let url = `${process.env.REACT_APP_MAST_SITE_URL}`;
    switch (source) {
      case "user":
        url += "ucenter";
        break;
      case "admin":
        url += "system/default";
        break;
      case "seller":
        url += "seller/index";
        break;
    }
    window.localStorage.setItem(`${process.env.REACT_APP_SOURCE_URL}`, url);
  },
};

export const appLog = Helper.appLog;
export const gotoDomPoistion = Helper.gotoDomPoistion;

export default Helper;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountMenu from "components/navigation/AccountMenu";
import { useUserStatus, useAppSelector, useAppDispatch } from "common/hooks";
import { MenuProp } from "common/libraries/interface/PropTypeCollection";

export default function MerchantNav() {
  return (
    <>
      {/*<HeaderMenu /> */}

      {/*
            <div className="subnavbar desktop">
                <div className="container">
                    <ul className="navbar-nav member-dashboard-navbarmenu">
                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('home')} className={`${merchantNav === 'home' ? 'nav-link active' : 'nav-link'}`} to="#">Dashboard</Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('account')} className={`${merchantNav === 'account' ? 'nav-link active' : 'nav-link'}`} to="#">My Account</Link>
                        </li>
                    </ul>
                </div>
            </div>
            */}
    </>
  );
}

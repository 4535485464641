import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUserStatus, useAppSelector, useAppDispatch } from "common/hooks";

export default function CashierNav() {
  const dispatch = useAppDispatch();
  return (
    <>
      {/*
            <HeaderMenu />
    */}
      {/*
            <div className="subnavbar desktop">
                <div className="container">
                    <ul className="navbar-nav member-dashboard-navbarmenu">
                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('home')} className={`${cashierNav === 'home' ? 'nav-link active' : 'nav-link'}`} to="#">Dashboard</Link>
                        </li>

                        <li className="nav-item">
                            <Link onClick={() => onHandleGoToTab('record-purchase')} className={`${cashierNav === 'record-purchase' ? 'nav-link active' : 'nav-link'}`} to="#">Record a Purchase</Link>
                        </li>
                    </ul>
                </div>
            </div>
    */}
    </>
  );
}

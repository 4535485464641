import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { callApi } from "adapter/ApiService";
import { AdminState, signinData } from "@/interface/PropTypeCollection";

const initialState: AdminState = {
  profileData: null,
  role: 8,
  authInfo: null,
  status: "idle",
  isAuth: null,
  message: "",
  avatar: null,
};

export const adminSignin = createAsyncThunk(
  "AdminResult/login",
  async (
    signinCredentials: signinData,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { name, password } = signinCredentials;
    try {
      const response = await callApi("user", "get/login", {
        name,
        password,
        role: 8,
      });
      //compare the role first
      if (response && response.state === 1) {
        window.localStorage.setItem(
          `${process.env.REACT_APP_ADMIN_LOGIN_KEY}`,
          JSON.stringify(response.data)
        );
        return fulfillWithValue(response.data);
      } else {
        window.localStorage.setItem(
          `${process.env.REACT_APP_ADMIN_LOGIN_KEY}`,
          JSON.stringify([])
        );
        return rejectWithValue({
          state: 0,
          error: {
            message: `Oops, login failed. Please verify that you have entered the correct information.!`,
          },
        });
      }
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const AdminSlice = createSlice({
  name: "AdminResult",
  initialState,
  reducers: {
    updateAdmin: (state, action) => {
      state.authInfo = action.payload;
      if (state.authInfo) {
        state.isAuth = true;
      }
    },
    updateAdminStatus: (state, action) => {
      const { status, message } = action.payload;
      state.status = status;
      state.message = message;
    },

    cleanAdminMessage: (state) => {
      state.message = "";
    },
    adminLogout: (state) => {
      state.isAuth = false;
      state.authInfo = null;
      window.localStorage.removeItem(
        `${process.env.REACT_APP_ADMIN_LOGIN_KEY}`
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminSignin.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(adminSignin.fulfilled, (state, action) => {
        state.status = "done";
        state.isAuth = true;
        state.authInfo = action.payload;
      })
      .addCase(adminSignin.rejected, (state, action) => {
        const resp: any = action.payload;
        const {
          error: { message },
        } = resp;
        state.status = "failed";
        state.isAuth = false;
        state.message = message;
        // state.currentRequestId = undefined;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  cleanAdminMessage,
  updateAdmin,
  updateAdminStatus,
  adminLogout,
} = AdminSlice.actions;

export default AdminSlice.reducer;

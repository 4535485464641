import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Show404 } from "widgets/Errors";
import SiteHeader from "components/navigation";

export default function Template() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <SiteHeader />
      <Outlet />
    </>
  );
}

import React, { useEffect, useState, useLayoutEffect } from "react";
import Maintenance from "./pages/Maintenance";
import AppRoutes from "AppRoutes";

function App() {
  const [idle, setIdle] = useState(false);
  const onHandlePassed = () => {
    setIdle(false);
  };

  useEffect(() => {
    setIdle(false);
  }, []);

  return (
    <div className="App">
      {idle ? <Maintenance onHandlePassed={onHandlePassed} /> : <AppRoutes />}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonLink from "widgets/ButtonLinks";
import { useNavigate } from "react-router-dom";
import { useUserStatus, useAppDispatch, useAppSelector } from "common/hooks";
import { updateStatus } from "common/features/UserSignupSlice";
import AccountMenu from "components/navigation/AccountMenu";
import { MenuNavProp } from "common/libraries/interface/PropTypeCollection";
import ConfirmModal from "components/common/ConfirmModal";
import AsyncAutoComplete from "components/common/AsyncAutoComplete";
import DropDownMenu from "./DropdownMenu";

export default function HeaderMenu({ simpleMode }: MenuNavProp) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userRole, user, loading, error } = useUserStatus();
  const { curPage, navMobileMenu, navMenu } = useAppSelector(
    (state) => state.PageSetup
  );
  const [showModal, setShowModal] = useState(false);

  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    setShowNav(false);
  }, [curPage]);

  const onClickSignout = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const onCloseUpModal = () => {
    setShowModal(false);
  };

  const onClickYes = () => {
    setShowModal(false);
    navigate("/user/logout");
  };

  const onClickNo = () => {
    setShowModal(false);
  };

  const onClickSignup = (e) => {
    e.preventDefault();
    dispatch(updateStatus("pending"));
    navigate("/signup/member");
  };

  useEffect(() => {
    if (userRole == 0) {
      /*
            setNavMobileMenu([...navMobileMenu, {
                page: 'login',
                title: "Member login",
                link: "/login/member",
            }, {
                page: 'login',
                title: "Merchant login",
                link: "/login/merchant",
            }])
            */
    }
  }, [userRole, curPage]);

  return (
    <>
      <ConfirmModal
        showModal={showModal}
        title=""
        message="You want to logout your account now ?"
        handleNo={onClickNo}
        handleYes={onClickYes}
        handleClose={onCloseUpModal}
      />
      <div className="topborder"> </div>
      <div className="header-menu sticky-top">
        {/* <LaunchNews /> */}
        <nav className="navbar navbar-expand-lg">
          <div className="container navbar-main">
            <button
              className={`navbar-toggler ${showNav ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mobilenavbar"
              aria-controls="mobilenavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowNav((prevState) => !prevState)}
            >
              <span
                className={`${
                  showNav ? "navbar-toggler:not(.collapsed)" : ""
                } navbar-toggler-icon`}
              ></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                {navMenu &&
                  navMenu.map((el, i) => {
                    if (el.children) {
                      return (
                        <DropDownMenu
                          key={`menu-item-desktop-${i}`}
                          title={el.title}
                          id="desktop-memeber-submenu"
                          menu_list={el.children}
                          page={"member"}
                        >
                          {el.title}
                        </DropDownMenu>
                      );
                    }

                    return (
                      <li className="nav-item" key={`menu-item-${i}`}>
                        <Link
                          aria-current="page"
                          className={`${
                            curPage == el.page ? "active" : ""
                          } nav-link`}
                          to={el.link}
                        >
                          {el.title}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              <AccountMenu />
            </div>
          </div>
          <div
            className={`${
              showNav ? "show" : ""
            } navbar-collapse justify-content-end collapse`}
            id="mobilenavbar"
          >
            <ul className="navbar-nav">
              {navMobileMenu &&
                navMobileMenu.map((el, i) => {
                  if (el.children) {
                    return (
                      <DropDownMenu
                        key={`menu-item-mobile-${i}`}
                        title={el.title}
                        id="desktop-memeber-submenu"
                        menu_list={el.children}
                        page={"member"}
                      >
                        {el.title}
                      </DropDownMenu>
                    );
                  }

                  return (
                    <li className="nav-item" key={`menu-mobile-item-${i}`}>
                      <Link
                        aria-current="page"
                        className={`${
                          curPage == el.page ? "active" : ""
                        } nav-link`}
                        to={el.link}
                      >
                        {el.title}
                      </Link>
                    </li>
                  );
                })}
              {user && (
                <li>
                  <Link
                    className="nav-link"
                    to="#"
                    onClick={(e) => onClickSignout(e)}
                  >
                    Logout
                  </Link>
                </li>
              )}
            </ul>
            {!user && !simpleMode && (
              <div className="d-grid">
                <ButtonLink
                  to="#"
                  onClick={(e) => onClickSignup(e)}
                  className="btn btn-primary header-button"
                >
                  JOIN FOR FREE
                </ButtonLink>
              </div>
            )}
          </div>
        </nav>
        {/*
                <div className="search-egnine d-flex align-items-end justify-content-center">
                    <AsyncAutoComplete />
                </div>
                */}
      </div>
    </>
  );
}
